import axios from "axios";
import DirectusSDK from "@directus/sdk-js";
import { growthStages } from "./constants";
const directus = new DirectusSDK("https://api.tangled-garden.net");

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export function uploadSeed(base64, userId, growthStage) {
  console.log("uploading seed", userId, growthStage);
  let formData = new FormData();
  const file = DataURIToBlob(base64);
  formData.append("seed_image", true);
  formData.append("growth_stage", growthStage);
  formData.append("user_ids", userId);
  formData.append("file", file);

  axios
    .post("https://api.tangled-garden.net/files", formData)
    .then(function (response) {
      console.log("successfully uploaded seed", userId, growthStage);
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function getSeeds() {
  return await axios
    .get(
      "https://api.tangled-garden.net/items/gardens?fields=seeds.*,seeds.image.*&limit=-1"
    )
    .then((response) => {
      const { data } = response;
      // console.log(data.data[0].seeds.length);
      let result = [];
      if (data && data.data) {
        const seeds = data.data[0].seeds;
        // console.log(seeds);
        return seeds.filter((seed) => seed.image != null);
      }
    })
    .catch((error) => console.error(error));
}

export async function getRandomSeed() {
  return await axios
    .get(
      "https://api.tangled-garden.net/items/gardens?fields=seeds.*,seeds.image.*&limit=-1"
      // "https://api.tangled-garden.net/items/gardens?fields=seeds.*,seeds.image&deep[seeds][limit]=-1"
    )
    .then((response) => {
      const { data } = response;
      if (data && data.data) {
        // console.log(data.data);
        const seeds = data.data[0].seeds;
        const filteredSeeds = seeds.filter((seed) => seed.image != null);
        return filteredSeeds[Math.floor(Math.random() * filteredSeeds.length)];
      }
      return null;
    })
    .catch((error) => console.error(error));
}

export async function getSeedCount() {
  return await axios
    .get(
      // "https://api.tangled-garden.net/items/gardens?fields=seeds.*,seeds.image.*&limit=1000"
      "https://api.tangled-garden.net/items/gardens?fields=seeds.*,seeds.image&deep[seeds][_limit]=-1"
    )
    .then((response) => {
      const { data } = response;
      if (data.data && data.data[0] && data.data[0].seeds) {
        const seeds = data.data[0].seeds;
        return seeds.filter((seed) => seed.growth_stage === 0).length;
      } else {
        console.error("could not fetch seed count", response);
        return -1;
      }
    })
    .catch((error) => console.error(error));
}

export async function getUserId() {
  return await axios
    .get("https://api.tangled-garden.net/custom/shortuuid")
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function getUserCount() {
  return await axios
    .get(
      "https://api.tangled-garden.net/items/user_ids?fields=seeds,hash&limit=-1"
    )
    .then(function (response) {
      const { data } = response;
      if (data && data.data) {
        const filtered = data.data.filter(function (el) {
          return el.seeds !== null;
        });
        return filtered.length;
      } else {
        return -1;
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function getSeedsByUser(userId) {
  return await axios
    .get(
      `https://api.tangled-garden.net/items/user_ids?limit=100&fields=hash,seeds.*.*&filter[hash][_eq]=${userId}`
    )
    .then((response) => {
      const { data } = response.data;
      let result = [null, null, null, null, null, null, null];
      if (data && data.length) {
        const seeds = data[0].seeds;

        if (seeds) {
          seeds.forEach((seed) => {
            const seedData = seed.seeds_id;
            if (seedData) {
              // console.log("seeds by user", seedData);
              // TODO: sort by date uploaded
              result[seedData.growth_stage] = seedData;
            }
          });
        }
      }
      return result;
    })
    .catch((error) => {
      console.error(error);
    });
}
export async function getSeedByUser(userId, growthStage) {
  // console.log("getting seed by user", userId, growthStage);
  return await axios
    .get(
      `https://api.tangled-garden.net/items/user_ids?limit=100&fields=hash,seeds.*.*&filter[hash][_eq]=${userId}`
    )
    .then(function (response) {
      const { data } = response.data;
      let result = null;
      data.forEach((item) => {
        const { seeds } = item;
        // console.log(seeds);
        if (seeds) {
          seeds.forEach((seed) => {
            const { seeds_id } = seed;
            const { growth_stage } = seeds_id;
            if (growth_stage === growthStage) {
              result = seeds_id;
            }
          });
        }
      });
      return result;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export function getAssetUrl(id, width, height) {
  // console.log("get asset url", )
  if (!id) {
    console.error("id is undefined", id, width, height);
    if (width && height) {
      return `https://placekitten.com/${width}/${height}`;
    } else {
      return `https://placekitten.com/800/800`;
    }
  }
  // both parameters are set
  if (width && height) {
    return `https://api.tangled-garden.net/assets/${id}?width=${width}&height=${height}&fit=cover`;
  }
  // only width is set
  if (width) {
    return `https://api.tangled-garden.net/assets/${id}?width=${width}`;
  }
  // any other case
  return `https://api.tangled-garden.net/assets/${id}`;
}
