import Raect from "react";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
export default function Camera() {
  const min = 5;
  const max = 600;
  useFrame((state) => {
    // console.log(state.camera.position);
    // state.camera.position.z = 50 + Math.sin(state.clock.getElapsedTime()) * 30;
    if (state.camera.position.z < min) {
      state.camera.position.z = min;
      state.camera.updateProjectionMatrix();
    }
    if (state.camera.position.z > max) {
      state.camera.position.z = max;
      state.camera.updateProjectionMatrix();
    }
  });
  return null;
}
