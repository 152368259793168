import React from "react";
import Sketch from "react-p5";
import { RGBA_ASTC_10x10_Format } from "three";
import { v4 as uuidv4 } from "uuid";
import Container from "../Container";
import {
  getIndex,
  getRed,
  getGreen,
  getBlue,
  getBrightness,
  setColor,
  createSetup,
  loadPixelsAndIterate,
  getBase64,
  wrap,
  AnimationManager,
  makeNoiseMap,
  minmax,
} from "../helpers";

let frameCounter = 0;
const id = "id_" + uuidv4();
var hasFinished = false;
/* p5 global vars */

let anMan;
let img1ref;

let imgScale;
let cnv;

let fc = 0;
let count;
let plotpoint1;
let plotpoint2;

function update(p5) {
  if (fc % 3 == 0 || true) {
    count += 1;
    if (count < 300) {
      phylo(p5, plotpoint1, count, 129);
    } else {
      phylo(p5, plotpoint1, count, 140);
      phylo(p5, plotpoint2, 500-count, 129);
    }
  }
}

function phylo(p5, vector, count, spacing) {
  try {
    let distance = 15 * p5.sqrt(count);
    vector.rotate(p5.radians(spacing));
    vector.normalize();
    vector.mult(distance);
  } catch (error) {
    pass;
  }
}

/**
 * take two images, intersect them by noise, fake animate the process
 *
 * @param {boolean} active
 * @param {*} src
 * @param {int} width
 * @param {int} height
 * @param {function} onFinished
 * @param {int} runtime - milliseconds until animation is "finished"
 * @param {int} frameDivider - animation is called every nth frame
 * @param {int} noiseScalar - noise Size Scalar
 */
export default function P5({
  active,
  src,
  width,
  height,
  onFinished,
  runtime = 8000,
  frameDivider = 1,
  ratioModifier = 20,
  imageScale = 80,
}) {
  const preload = (p5) => {
    // fetch imgs, handle only one img somewhat gracefully
    img1ref = p5.loadImage(src);
  };

  const setup = (p5, canvasParentRef) => {
    // barebones p5
    // createSetup(width, height, src)(p5, canvasParentRef);
    p5.createCanvas(width, height).parent(canvasParentRef);

    p5.pixelDensity(1);

    // animation management
    anMan = new AnimationManager(p5, runtime);
    //p5.background(1);
    //p5.angleMode(p5.DEGREES);
    console.log(anMan._running);
    count = 0;
    plotpoint1 = p5.createVector(1, 1);
    plotpoint2 = p5.createVector(1, 1);

    imgScale = {
      w: imageScale,
      h: (img1ref.height * imageScale) / img1ref.width,
    };
    img1ref.resize(imgScale.w, imgScale.h);
  };
  const draw = (p5) => {
    if (!active) return 0; // if not active skip block
    if (frameDivider != 1) {
      // check if framedivider is in effect
      frameCounter++;
      if (frameCounter % frameDivider != 0) {
        // if not on "active" frame return
        return 1;
      }
    }

    anMan.start();
    let animationHead = anMan.update();

    /*
    creative part start
    */
    fc++;

    update(p5);
    p5.translate((width - img1ref.width) / 2, (height - img1ref.height) / 2);
    if (count % 4) {
      p5.tint(255, 230, 200);
      p5.image(img1ref, plotpoint1.x, plotpoint1.y);
    } else {
      p5.tint(200, 255, 200);
      p5.image(img1ref, plotpoint1.x, plotpoint1.y);
    }

    if (count > 350) {
      p5.image(img1ref, plotpoint2.x, plotpoint2.y);
    }

    /*
    creative part end
    */

    if (fc > 1200 && !hasFinished) {
      // <= SET END FRAME HERE!
      hasFinished = true;
      // if past runtime trigger finished event
      if (onFinished) {
        onFinished(getBase64(id));
      }
      return 2;
    }
    return 3;
  };
  const debugDraw = (p5) => {
    // console.log({
    //   active,
    //   src,
    //   src2,
    // });
    // console.log(draw(p5));
    draw(p5);
  };

  return (
    <Container width={width} height={height} id={id}>
      <Sketch preload={preload} setup={setup} draw={debugDraw} />
    </Container>
  );
}
