
import React, { useEffect, useState } from 'react';

// == Import
import './styles.scss';


// == Composant
const ScrollIndicator = () => {

  return (
    <div className="scroll-indicator"
      data-5100="opacity:0"
      data-5200="opacity:1"
    >
		<span className="si__dot"
     data-235000="margin-left:19.301333333333332px;margin-top:-0.4951025641025641px"
     data-240000="margin-left:2.5px;margin-top:0px"
    ></span>
	</div>

  );
};

// == Export
export default ScrollIndicator;
