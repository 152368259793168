import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import QRCode from "react-qr-code";
import Restart from "../Restart";
import { growthStages } from "../../constants";
import Seed from "../Seed";
import Image from "../Utils/image";
import config from "../../config.json";

// == Import
import "./styles.scss";

// == Composant
const Saen = ({ setFlowerClicked }) => {
  const userId = useStore((state) => state.userId);
  const currentStep = useStore((state) => state.currentStep);
  const seed = useStore((state) => state.seed);
  const saen = useStore((state) => state.saen);
  const seedAmount = useStore((state) => state.seedCount);

  const flowers = [
    "269ece33-c258-4ae5-8382-661a6fa4afdc",
    "afa840d0-bcf1-49cd-9906-1900361cab6e",
    "8295ef86-a939-4742-8a54-f6245c40fc4b",
    "4d96c21c-3de4-4b89-b5fc-bb941fd83900",
    "90320485-e419-4b7c-aaa6-a9cf56a4fce4",
    "0272fe49-2d95-4871-89f4-a24454f4c12e",
  ];

  const playFlower = (index) => {
    if (!document.querySelector(`.floweraudio--${index}`).paused)
      document.querySelector(`.floweraudio--${index}`).pause();
    else document.querySelector(`.floweraudio--${index}`).play();
  };

  return (
    <section
      className="section section--saen"
      id="saen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0;pointer-events:none"
      data-4000="display:none;pointer-events:none;position:fixed;top:0;left:0;pointer-events:all"
      data-4001="display:flex;pointer-events:all;position:fixed;top:0;left:0;pointer-events:all"
      data-22200="display:flex;pointer-events:all;position:fixed;top:0;left:0;pointer-events:all"
      data-22201="display:flex;pointer-events:none;position:fixed;top:0;left:0;pointer-events:none"
    >
      <div
        className="saen__text"
        data-4000="right:-600px;top: 130px;"
        data-5000="right:100px;top: 130px;"
        data-6000="right:100px;top: 130px;"
        data-7050="right:200px;top:-1300px"
      >
        {/* {currentStep === 1 && ( */}
        <div className="saen__text__1">
          To see data come to life, to flourish and decay, please donate a piece
          of data from your phone to the Garden.
          {/* <br />
          <br /> */}
          {!seed && (
            <>
              <div
                className="saen__qr"
                // data-0="right:-250px"
                // data-5000="right:-250px"
                // data-5200="right:30px"
                // data-6500="opacity:1"
                // data-6600="opacity:0"
              >
                <QRCode value={`${config.uploadUrl}/${userId}`} />
              </div>
              Use your phone to scan this QR Code You need your phone to plant
              the seed.
              <br />
              <br />
              We need you to make the Garden flourish.
              <br />
              <br />
              In case you cannot use your phone's QR Code reader, please type
              the following URL in your phone's web browser: <br />
              <br />
              <span className="underline">{`${config.uploadUrl}/${userId}`}</span>
            </>
          )}
          <br />
          <br />
          Become part of the coming Ecosystem.
          <br />
          {!seed && <span style={{ color: "orangered" }}><br/>Waiting for your seed to be planted...</span>}
          {seed && !saen && (
            <span style={{ color: "orangered" }}>
              <br />Your seed has been planted.
              <br />
              You can now enter the garden by scrolling.
            </span>
          )}
          <br />
          {seed && saen && (
            <span style={{ color: "orangered" }}>
              Seed has already been planted in an earlier session, 
              <br/>either scroll through the existing results or <br />
              <a href={config.url} target="_blank" style={{ color: "orangered" }}>
                plant a new seed.
              </a>
            </span>
          )}
        </div>
        {/* )} */}
      </div>

      <div
        className="saen__flowers"
        data-7000="height:0px;opacity:1"
        data-7500="height:150px;opacity:1"
        data-22200="height:150px;opacity:1"
        data-22400="height:150px;opacity:0"
        data-22410="height:0px;opacity:0"
        data-29000="height:0px;opacity:1"
        data-29500="height:150px;opacity:1"
        data-33300="height:150px;opacity:1"
        data-33500="height:150px;opacity:0"
        data-33510="height:0px;opacity:0"
        data-59000="height:0px;opacity:1"
        data-59500="height:150px;opacity:1"
        data-68500="height:150px;opacity:1"
        data-68700="height:150px;opacity:0"
        data-68710="height:0px;opacity:0"
        data-109000="height:0px;opacity:1"
        data-109500="height:150px;opacity:1"
        data-117000="height:150px;opacity:1"
        data-117200="height:150px;opacity:0"
        data-117210="height:0px;opacity:0"
        data-141000="height:0px;opacity:1"
        data-141500="height:150px;opacity:1"
        data-150000="height:150px;opacity:1"
        data-150200="height:150px;opacity:0"
        data-150210="height:0px;opacity:0"
      >
        {flowers.map((flower, index) => (
          <div
            className={`flower flower${index + 1}`}
            onClick={() => playFlower(index + 1)}
            onMouseOver={() => {
              if (index === 0) {
                document.querySelector(".flower1").style.animation =
                  "wave2 infinite 11s ease-in-out";
                // TODO: i am confused here, what should it do? lock or unlock?
                // document.querySelector("html").style.overflow = "scroll";
                setFlowerClicked(true);
              }
            }}
            style={{
              pointerEvents: index + 1 === currentStep ? "all" : "none",
            }}
            key={index}
          >
            <Image id={flower} />
          </div>
        ))}
      </div>

      <h2
        className="saen__title"
        data-0="opacity:0"
        data-7800="opacity:0"
        data-8100="opacity:1"
        data-9100="opacity:0"
      >
        SÄEN
      </h2>

      <div
        className="userphoto userphoto--1"
        data-0="opacity:0"
        data-8000="opacity:0;filter: blur(100px);"
        data-9000="opacity:1;filter: blur(100px);"
        data-10000="opacity:1;filter: blur(0px);"
        data-13500="opacity:1"
        data-14000="opacity:0"
      >
        {/* PUT USER IMAGE 1 HERE AND REMOVE THE <img /> */}
        <div
          className="userphoto__info userphoto__info--1"
          // data-10000="display:none;position:absolute"
          // data-10500="display:block;position:absolute"
          // data-14000="display:none;position:absolute"
        >
          {/* Seed {seedAmount}
        <br/>Generation 1
        <br/>{new Date().toLocaleString()} */}
        </div>
        <Seed growthStage={growthStages.saen}></Seed>
      </div>

      <div
        className="saen__floater saen__floater--1"
        data-0="left:80vw;top:100vh"
        data-7000="left:80vw;top:100vh"
        data-9000="left:-30vw;top:50vh"
      >
        <Image id="8cadb367-4685-452f-b047-97ac467fdc2e" />
      </div>
      <div
        className="saen__floater saen__floater--2"
        data-0="display:none"
        data-16000="display:block"
        data-16200="display:none"
        data-16400="display:block"
        data-16500="display:none"
        data-16550="display:block"
        data-16800="display:none"
      >
        <Image id="d8a26ebc-3cb4-42c6-aaaa-a1bbbc3fdc5c" />
      </div>
      <div
        className="saen__floater saen__floater--3"
        data-0="left:100vw;top:40vh"
        data-17000="left:100vw;top:40vh"
        data-20000="left:-60vw;top:80vh"
      >
        <Image id="83a1962b-0087-4474-9e60-1f7fa438ff7b" />
      </div>

      <div
        className="saen__ty"
        data-0="opacity:0"
        data-6050="opacity:0"
        data-6700="opacity:1"
        data-8500="opacity:1"
        data-9000="opacity:0"
      >
        <p
          data-6700="opacity:1;position:absolute"
          data-7200="opacity:0;position:absolute"
        >
          Thank you.
        </p>
        <p
          data-7000="opacity:0;position:absolute"
          data-7500="opacity:1;position:absolute"
          data-8000="opacity:0"
        >
          The seed is planted.
        </p>
        <p
          data-7700="opacity:0;position:absolute"
          data-8100="opacity:1;position:absolute"
          data-9100="opacity:0"
        >
          You are becoming part of the Coming Ecosystem.
        </p>
      </div>

      {/* <p className="saen__si" 
      data-0="opacity:0" 
      data-1700="opacity:0" 
      data-1750="opacity:1" 
      data-2100="opacity:0"
    >
			Step in. Stroll around. Scroll around.
			<br/>Take your time. 
		</p> */}

      <div
        className="saen__step"
        data-0="display:none"
        data-11000="display:block"
        data-17100="display:block"
      >
        <span
          className="saen__step__s"
          data-11500="position:absolute;opacity:0"
          data-12000="position:absolute;opacity:1"
          data-16000="position:absolute;opacity:0"
        >
          S
        </span>
        <span
          className="saen__step__t"
          data-11700="position:absolute;opacity:0"
          data-12200="position:absolute;opacity:1"
          data-16200="position:absolute;opacity:0"
        >
          T
        </span>
        <span
          className="saen__step__e"
          data-11900="position:absolute;opacity:0"
          data-12400="position:absolute;opacity:1"
          data-16400="position:absolute;opacity:0"
        >
          E
        </span>
        <span
          className="saen__step__p"
          data-12100="position:absolute;opacity:0"
          data-12600="position:absolute;opacity:1"
          data-16600="position:absolute;opacity:0"
        >
          P
        </span>
        <span
          className="saen__step__i"
          data-12300="position:absolute;opacity:0"
          data-12800="position:absolute;opacity:1"
          data-16800="position:absolute;opacity:0"
        >
          I
        </span>
        <span
          className="saen__step__n"
          data-12500="position:absolute;opacity:0"
          data-13000="position:absolute;opacity:1"
          data-17000="position:absolute;opacity:0"
        >
          N
        </span>
      </div>
      <div
        className="saen__stroll"
        data-15000="display:none"
        data-15500="display:block"
        data-18500="display:none"
      >
        Stroll around.
      </div>
      <div
        className="saen__scroll"
        data-15000="display:none"
        data-15500="display:block"
        data-18500="display:none"
      >
        Scroll around.
      </div>
      <div
        className="saen__waiting"
        data-18500="display:none;opacity:1"
        data-18501="display:block;opacity:1"
        data-21700="display:block;opacity:1"
        data-21900="display:block;opacity:0"
        data-21901="display:none;opacity:0"
      >
        <p
          data-18500="opacity:0;padding-left:300px"
          data-19500="opacity:1;padding-left:0px"
        >
          Take your time
        </p>
        <p
          data-19500="opacity:0;padding-right:200px"
          data-20500="opacity:1;padding-right:0px"
        >
          We will be waiting for you{" "}
        </p>
        <p
          data-20500="opacity:0;padding-left:200px"
          data-21500="opacity:1;padding-left:0px"
        >
          in the compost pile at the exit gate.
        </p>
      </div>

      <div
        className="saen__compost"
        data-0="opacity:0;display:none"
        data-21000="opacity:0;display:block"
        data-21200="opacity:1;display:block"
        data-22000="opacity:1;display:block"
        data-22200="opacity:0;display:block"
        data-22201="opacity:0;display:none"
      >
        <Image id="2f4ab0e2-f424-4744-b044-2ab4847c44ca" />
      </div>
    </section>
  );
};

// == Export
export default Saen;
