import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
// import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Image from "./Utils/image";

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const DataPolicy = styled.section`
  text-align: right;
  z-index: 1;
  span {
    color: lightgreen;
    z-index: 10;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;
const Button = styled.button`
  font-size: 32px;
  background-color: lightgreen;
`;

const Success = styled.div`
  position: absolute;
  bottom: 2vh;
  width: 80vw;
  font-size: 20px;
  font-family: "reg";
  margin: 10vw;
`;

const Top = styled.section`
  text-align: center;
  padding: 25px;
  padding-top: 10vh;
  h1 {
    font-size: 32px;
  }
`;
const Center = styled.section`
  flex-grow: 1;
  vertical-align: middle;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Bottom = styled.section`
  display: flex;
  flex-direction: row;
  z-index: 1;
  @media (min-width: 600px) {
    display: none;
  }
`;

const ImageBooth = styled.img`
  border: 1px solid white;
  text-align: center;
  bottom: 5vh;
  width: 60vw;
  height: 60vw;
  object-fit: cover;
`;

const DropZone = styled.div`
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  font-family: "reg";
  font-size: 4vw;
  bottom: 25vh;
  width: 80vw;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default function PhotoBooth({ uploadCallback }) {
  const { userId } = useParams();
  const [uploaded, setUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [consent, setConsent] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const upload = useCallback(
    (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        console.log(reader.result);
        setImgSrc(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    [setImgSrc]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        upload(file, userId);
        // console.log(URL.createObjectURL(file));
      });
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Container>
      <div className="upload__computer">
        Please upload your picture from a phone.
      </div>
      <div className="mobile__plants">
        <Image id="d589e744-92f4-4617-b8c6-7710fde86d98" />
      </div>

      {uploaded && (
        <Success>
          Thanks for contributing, your seed is on its way to the Tangled Garden
          ecosystem. It will be there in a few seconds.
        </Success>
      )}
      {!uploaded && (
        <>
          <Top>
            <h1>
              The Garden
              <br />
              of Tangled Data
            </h1>
          </Top>
          <Center>
            {imgSrc && <ImageBooth src={imgSrc}></ImageBooth>}
            {!imgSrc && (
              <DropZone {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Choose a picture to upload to the garden</p>
              </DropZone>
            )}
          </Center>

          <DataPolicy>
            agree to{" "}
            <span
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              data policy
            </span>
            <Checkbox
              color="primary"
              checked={consent}
              onChange={(e, checked) => {
                console.log(checked);
                setConsent(checked);
              }}
            />
          </DataPolicy>
          {showMore && <Bottom>Data policy comes here</Bottom>}
          <Bottom>
            <Button
              onClick={(e) => {
                uploadCallback(imgSrc, userId);
                setTimeout(() => {
                  setUploaded(true);
                }, 3000);
              }}
              color="primary"
              variant="contained"
              style={{ flexGrow: 1 }}
              disabled={!consent}
            >
              Upload
            </Button>
          </Bottom>
        </>
      )}
    </Container>
  );
}
