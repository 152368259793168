import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import postscribe from "postscribe";
import $ from "jquery";
import config from "../../config.json";
import useQuery from "../../hooks/useQuery";
import differenceInSeconds from "date-fns/differenceInSeconds";

// == styles
import "./styles.scss";
import "./fixed-positioning.css";

// == scripts
// import script1 from '/src/assets/scripts/require.js';
// import script2 from '/src/assets/scripts/main.js';
import {
  getUserId,
  getUserCount,
  getSeedsByUser,
  getSeeds,
  getSeedCount,
  getRandomSeed,
} from "../../api";
import { growthStages } from "../../constants";

// == state
import { useStore } from "../../store";

// == components
import Introduction from "../Introduction";
import Saen from "../Saen";
import Keimen from "../Keimen";
import Audios from "../Audios";
import People from "../People";
import Verzweigen from "../Verzweigen";
import Blumen from "../Blumen";
import ScrollIndicator from "../ScrollIndicator";
import Kreuzen from "../Kreuzen";
import Welken from "../Welken";
import Vermodern from "../Vermodern";
// import Mobile from "../Mobile";
import Debugger from "../Debug/HeaderBar";
import VideoCursor from "../VideoCursor";
import HyperCam from "../HyperCam";
import Backgrounds from "../Backgrounds";

let previousScroll = 0;
let scroll = 0;
let seedUploaded = null;
let alert = true;

let vid = true;
let introVid = true;
let presVid = true;
let audioPlaying = false;
let vid1 = true;
let seeds = true;
let vid2 = true;
let copied = true;
let pasted = true;
let flowerClicked = false;
let imageInfo = [true, true, true, true, true, true, true];
let videoCursorDisplayed = false;
// LAURA — TO REMOVE
// vid = false;
// introVid = false;
// presVid = false;
// seeds = false;
// flowerClicked = true;
let seedAmount = 0;
let refresh = true;

const App = () => {
  let branchingPic = false;

  const devMode = useStore((state) => state.devMode);
  const userId = useStore((state) => state.userId);
  const currentStep = useStore((state) => state.currentStep);
  const seedCount = useStore((state) => state.seedCount);
  const growthStage = useStore((state) => state.growthStage);

  const setUserId = useStore((state) => state.setUserId);
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const setGrowthStage = useStore((state) => state.setGrowthStage);
  const setUserCount = useStore((state) => state.setUserCount);
  const setSeedCount = useStore((state) => state.setSeedCount);

  const seed = useStore((state) => state.seed);
  const selectedSeed = useStore((state) => state.selectedSeed);
  const saen = useStore((state) => state.saen);
  const keimen = useStore((state) => state.keimen);
  const verzweigen = useStore((state) => state.verzweigen);
  const bluehen = useStore((state) => state.bluehen);
  const kreuzen = useStore((state) => state.kreuzen);
  const welken = useStore((state) => state.welken);

  const setSeed = useStore((state) => state.setSeed);
  const setSelectedSeed = useStore((state) => state.setSelectedSeed);
  const setSaen = useStore((state) => state.setSaen);
  const setKeimen = useStore((state) => state.setKeimen);
  const setVerzweigen = useStore((state) => state.setVerzweigen);
  const setBluehen = useStore((state) => state.setBluehen);
  const setKreuzen = useStore((state) => state.setKreuzen);
  const setWelken = useStore((state) => state.setWelken);

  const saenActive = useStore((state) => state.saenActive);
  const keimenActive = useStore((state) => state.keimenActive);
  const verzweigenActive = useStore((state) => state.verzweigenActive);
  const bluehenActive = useStore((state) => state.bluehenActive);
  const kreuzenActive = useStore((state) => state.kreuzenActive);
  const welkenActive = useStore((state) => state.welkenActive);

  const setSaenActive = useStore((state) => state.setSaenActive);
  const setKeimenActive = useStore((state) => state.setKeimenActive);
  const setVerzweigenActive = useStore((state) => state.setVerzweigenActive);
  const setBluehenActive = useStore((state) => state.setBluehenActive);
  const setKreuzenActive = useStore((state) => state.setKreuzenActive);
  const setWelkenActive = useStore((state) => state.setWelkenActive);

  const setHyperCam = useStore((state) => state.setHyperCam);

  const setVideoA = useStore((state) => state.setVideoA);
  const setVideoB = useStore((state) => state.setVideoB);
  const setVideoC = useStore((state) => state.setVideoC);
  const setVideoD = useStore((state) => state.setVideoD);
  const setVideoE = useStore((state) => state.setVideoE);
  const setVideoF = useStore((state) => state.setVideoF);
  const setVideoG = useStore((state) => state.setVideoG);
  const setVideoH = useStore((state) => state.setVideoH);
  const setVideoI = useStore((state) => state.setVideoI);
  const setVideoJ = useStore((state) => state.setVideoJ);
  const setVideoK = useStore((state) => state.setVideoK);

  const videoD = useStore((state) => state.videoD);
  const scrollLocked = useStore((state) => state.scrollLocked);
  const scrollLockedPosition = useStore((state) => state.scrollLockedPosition);
  const scrollLockedTimestamp = useStore(
    (state) => state.scrollLockedTimestamp
  );
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  const setScrollLockedTimestamp = useStore(
    (state) => state.setScrollLockedTimestamp
  );

  // const subscription = useStore.subscribe((value) => console.log(value));

  let history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (userId) {
      history.push(`/session/${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    // TODO: that should make use of refs
    if (scrollLocked) {
      if (scrollLockedPosition) {
        // console.log(
        //   "smoth scroll to there if distance is greater than threshold"
        // );
      }

      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
  }, [scrollLocked, scrollLockedPosition]);

  useEffect(() => {
    seedUploaded = seed;
    // LAURA — TO REMOVE
    // unlock in debug mode
    // if (debug) {
    // seedUploaded = true;
    // }
    if (seed !== null) {
      // introVid = false;
      // vid = false;
      // presVid = false;
      setScrollLocked(false);
    }
  }, [seed]);

  useEffect(() => {
    if (seedAmount === 0) seedAmount = seedCount;
  }, [seedCount]);

  useEffect(() => {
    setTimeout(() => {
      postscribe(
        `#app`,
        '<script language="javascript" src="/main.js"></script>'
      );
    }, 20);
  }, []);

  // registering scroll handler
  // only happens once
  useEffect(() => {
    createCircle();
    // window.addEventListener("wheel", scrollFunc);
    // window.addEventListener("mousewheel", scrollFunc);
    // window.addEventListener("DOMMouseScroll", scrollFunc);
    window.addEventListener("scroll", scrollFunc);
    return () => {
      window.removeEventListener("wheel", scrollFunc);
    };
  }, []);

  // fetching a new user id
  // only happens once
  useEffect(() => {
    if (params.userId) {
      console.log("got user id via url param", params.userId);
      setUserId(params.userId);
    } else {
      getUserId().then((response) => {
        console.log("got new user id", response);
        setUserId(response);
      });
    }
  }, []);

  // fetching users and seedcount
  // happens every 10s
  useEffect(() => {
    const id = setInterval(() => {
      getSeedCount().then((response) => {
        setSeedCount(response);
      });
      getUserCount().then((response) => {
        setUserCount(response);
      });
    }, config.updateInterval);
    return () => {
      clearInterval(id);
    };
  }, []);

  // TODO: move that to saen component or somewhere else
  // fetching seed by user
  // happens every 10s
  useEffect(() => {
    const id = setInterval(() => {
      //   console.log(seed, saen, keimen, verzweigen, bluehen, kreuzen, welken);
      if (!selectedSeed) {
        getRandomSeed().then((response) => {
          if (response) {
            setSelectedSeed(response);
          }
        });
      }

      getSeedsByUser(userId).then((response) => {
        if (!response) {
          console.log("invalid response", response);
          return;
        }
        // console.log("getting seeds", response);
        if (!seed && response[growthStages.initial]) {
          // console.log("set seed");
          setSeed(response[growthStages.initial]);
        }
        if (!saen && response[growthStages.saen]) {
          // console.log("set saen");
          setSaen(response[growthStages.saen]);
        }
        if (!keimen && response[growthStages.keimen]) {
          // console.log("set keimen");
          setKeimen(response[growthStages.keimen]);
        }
        if (!verzweigen && response[growthStages.verzweigen]) {
          // console.log("set verzweigen");
          setVerzweigen(response[growthStages.verzweigen]);
        }
        if (!bluehen && response[growthStages.bluehen]) {
          // console.log("set bluehen");
          setBluehen(response[growthStages.bluehen]);
        }
        if (!kreuzen && response[growthStages.kreuzen]) {
          // console.log("set kreuzen");
          setKreuzen(response[growthStages.kreuzen]);
        }
        if (!welken && response[growthStages.welken]) {
          // console.log("set welken");
          setWelken(response[growthStages.welken]);
        }
      });
    }, config.updateInterval);
    return () => {
      clearInterval(id);
    };
  }, [userId, seed, saen, keimen, verzweigen, bluehen, kreuzen, welken]);

  // Intro
  useEffect(() => {
    setTimeout(() => {
      // if (!devMode) {
      // window.scrollTo(0, 0);
      document.querySelector(".intro__click").style.backgroundColor =
        "rgba(0,0,0,0.6)";
      document.querySelector("body").style.height = "251000px";
      document.querySelector(".intro__click__square").style.pointerEvents =
        "all";
      document.querySelector(".intro__click__square").style.color = "white";
      // }
      document.querySelector(`.flower1`).style.opacity = 1;
      document.querySelector(`.flower1`).style.cursor = "pointer";
      document.querySelector(`.flower1`).style.filter = "grayscale(0%)";
    }, 1000);
  }, []);

  // unlocking lock 30s after last lock timestamp
  // happens every 10s
  // useEffect(() => {
  //   const id = setInterval(() => {
  //     if (
  //       scrollLocked &&
  //       differenceInSeconds(new Date(), scrollLockedTimestamp) > 30
  //     ) {
  //       console.log("fallback scroll unlock");
  //       // TODO: maybe dont unlock if seed is not yet set
  //       setScrollLocked(false);
  //     }
  //   }, config.updateInterval);
  //   return () => {
  //     clearInterval(id);
  //   };
  // }, [scrollLocked, scrollLockedTimestamp]);

  const scrollFunc = () => {
    const pos = window.scrollY;
    const diff = Math.abs(pos - scroll);
    // console.log(scroll);
    if (diff > 10) {
      // console.log(diff);
      scrollAnimation();
      scroll = pos;

      // Steps
      if (0 < pos && pos < 22000) {
        if (currentStep !== 1) {
          setCurrentStep(1);
        }
      } else if (22000 < pos && pos < 65000) {
        document.querySelector(".kreuzen-audio").pause();
        if (currentStep !== 2) {
          setCurrentStep(2);
        }
      } else if (65000 < pos && pos < 110000) {
        if (currentStep !== 3) {
          setCurrentStep(3);
        }
      } else if (110000 < pos && pos < 155000) {
        if (currentStep !== 4) {
          setCurrentStep(4);
        }
      } else if (155000 < pos && pos < 200000) {
        if (currentStep !== 5) {
          setCurrentStep(5);
        }
      } else if (200000 < pos && pos < 250000) {
        if (currentStep !== 6) {
          setCurrentStep(6);
        }
      }

      if (pos > 6000) {
        // CHANGE STATES INTRO
        vid = false;
        introVid = false;
        presVid = false;
        // setAudioPlaying(false);
      }
      if (pos > 22000) {
        // CHANGE STATES SÄEN
        flowerClicked = true;
        imageInfo[0] = false;
      }
      if (pos > 65000) {
        // CHANGE STATES KEIMEN
        vid1 = false;
        seeds = false;
        vid2 = false;
        imageInfo[1] = false;
      }
      if (pos > 110000) {
        // CHANGE STATES VERZWEIGEN
        imageInfo[2] = false;
        // document.querySelector(".kreuzen-audio").pause();
      }
      if (pos > 155000) {
        // CHANGE STATES BLUMEN
        alert = false;
        imageInfo[3] = false;
      }
      if (pos > 200000) {
        // CHANGE STATES KREUZEN
        copied = true;
        pasted = true;
        imageInfo[4] = false;
      }

      if (pos > 250000) {
        // CHANGE STATES KELMEN
        imageInfo[5] = false;
      }

      if (0 < pos && pos < 8000) {
        if (growthStage !== growthStages.none) {
          setGrowthStage(growthStages.none);
        }
      }
      // Growth stages
      // saen
      else if (8000 < pos && pos < 14000) {
        if (growthStage !== growthStages.saen) {
          setGrowthStage(growthStages.saen);
        }
      }
      // keimen
      else if (27000 < pos && pos < 34000) {
        // console.log('KEIMEN START');
        if (growthStage !== growthStages.keimen) {
          setGrowthStage(growthStages.keimen);
          // triggerUserInfo(2)
        }
      }
      // verzweigen
      else if (82000 < pos && pos < 90000) {
        if (growthStage !== growthStages.verzweigen) {
          setGrowthStage(growthStages.verzweigen);
        }
      }
      // bluehen
      else if (118000 < pos && pos < 126000) {
        if (growthStage !== growthStages.bluehen) {
          setGrowthStage(growthStages.bluehen);
        }
      }
      // kreuzen
      else if (165000 < pos && pos < 182000) {
        if (growthStage !== growthStages.kreuzen) {
          setGrowthStage(growthStages.kreuzen);
        }
        if(document.querySelector(".kreuzen-audio").paused){
          document.querySelector(".kreuzen-audio").play();
          document.querySelector(".welken-audio").pause();
        }
      }
      // welken
      else if (215000 < pos && pos < 221000) {
        if (growthStage !== growthStages.welken) {
          setGrowthStage(growthStages.welken);
        }
        if(document.querySelector(".welken-audio").paused){
          document.querySelector(".kreuzen-audio").pause();
          document.querySelector(".welken-audio").play();
        }
      }
      
      if(pos < 215000 && !document.querySelector(".welken-audio").paused) {
        document.querySelector(".kreuzen-audio").play();
      }

      if (9500 < pos && pos < 14000) {
        if (!saenActive) {
          setSaenActive(true);
        }
        if (imageInfo[1]) triggerUserInfo(1);
      }
      // only in debug, otherwise set keimen active is done in check seed
      // if (debug && 10000 < pos && pos < 14000) {
      //   if (!keimenActive) {
      //     setKeimenActive(true);
      //   }
      // }
      else if (83000 < pos && pos < 90000) {
        if (!verzweigenActive) {
          setVerzweigenActive(true);
        }
        if (imageInfo[3]) triggerUserInfo(3);
      } else if (119000 < pos && pos < 126000) {
        if (!bluehenActive) {
          setBluehenActive(true);
        }
        if (imageInfo[4]) triggerUserInfo(4);
      } else if (166000 < pos && pos < 175000) {
        if (!kreuzenActive) {
          setKreuzenActive(true);
        }
        if (imageInfo[5]) triggerUserInfo(5);
      } else if (217000 < pos && pos < 221000) {
        if (!welkenActive) {
          setWelkenActive(true);
        }
        if (imageInfo[6]) triggerUserInfo(6);
      }

      // LAURA — TO REMOVE
      if (pos > 28600 && pos < 34000) {
        if (imageInfo[2]) {
          triggerUserInfo(2);
          setKeimenActive(true);
        }
      }

      branchingPic = pos > 63000 && pos < 69000;

      if(scroll > 235000 && !document.querySelector(".general-audio").paused) {
        document.querySelector(".hypercam-audio").play();
        document.querySelector(".general-audio").pause();
      }

      if(scroll < 235000 && !document.querySelector(".hypercam-audio").paused) {
        document.querySelector(".hypercam-audio").pause();
        document.querySelector(".general-audio").play();
      }

      // hypercam
      if (240000 < pos && pos < 300000) {
        // if (!HyperCam) {
          document.querySelector(".welken-audio").pause();
          setHyperCam(true);
        // }
      } else {
        document.querySelector(".hypercam-audio").pause();
      }
    }
  };

  const moveCursor = (e) => {
    if (scroll > 116000 && scroll < 130000) {
      $(".blumen__round").offset({
        left: e.pageX - 40,
        top: e.pageY - 40,
      });
    }
  };

  const moveCursorSlogan = (e) => {
    if (scroll > 238000 && scroll < 240000) {
      $(".welken__mouse").offset({
        left: e.pageX - 40,
        top: e.pageY - 40,
      });
    }
  };

  // KREUZEN
  const moveBinary = (e) => {
    $(".kreuzen__binary__1").offset({
      left: e.pageX - 150,
      top: e.pageY - 25,
    });
    $(".kreuzen__binary__2").offset({
      left: window.innerWidth - e.pageX - 150,
      top: e.pageY - 25,
    });
  };

  useEffect(() => {
    if (currentStep !== 1) {
      for (let index = currentStep - 1; index < currentStep + 1; index++) {
        if (index !== 0 && index !== 8 && index !== currentStep) {
          if (!document.querySelector(`.floweraudio--${index}`).paused)
            document.querySelector(`.floweraudio--${index}`).pause();
        }
      }
      // TODO: Add again
      document.querySelector(`.flower${currentStep}`).style.opacity = 1;
      document.querySelector(`.flower${currentStep}`).style.cursor = "pointer";
      document.querySelector(`.flower${currentStep}`).style.pointerEvents =
        "all";
      document.querySelector(`.flower${currentStep}`).style.filter =
        "grayscale(0%)";
      for (let index = currentStep - 1; index < currentStep + 1; index++) {
        if (index !== 0 && index !== 8 && index !== currentStep) {
          document.querySelector(`.flower${index}`).style.opacity = 0.4;
          document.querySelector(`.flower${index}`).style.cursor = "default";
          document.querySelector(`.flower${index}`).style.pointerEvents =
            "none";
          document.querySelector(`.flower${index}`).style.filter =
            "grayscale(100%)";
        }
      }
    }
  }, [currentStep]);

  const createCircle = () => {
    const R = 753000;
    const steps = 245;
    for (let i = 0; i < steps; i++) {
      const iAngle = i < 61 ? i + 180 : i - 61;
      let xVal = Math.round(R * Math.cos((2 * Math.PI * i) / steps));
      let yVal = Math.round(R * Math.sin((2 * Math.PI * i) / steps));
      let newData = `background-position:${xVal / 100}px ${yVal / 100}px`;
      let newDataSmall = `margin-left:${xVal / 39000}px;margin-top:${
        yVal / 39000
      }px`;
      document
        .querySelector(".si__dot")
        .setAttribute(`data-${i * 1000 + 1000}`, newDataSmall);

      const backgrounds = document.querySelectorAll(".background--inner");
      for (let index = 0; index < backgrounds.length; index++) {
        backgrounds[index].setAttribute(
          `data-${iAngle * 1000 + 1000}`,
          newData
        );
      }
    }
  };

  const checkSeeds = () => {
    console.log(
      "remaining: " + document.querySelectorAll(".keimen__seed").length
    );
    if (document.querySelectorAll(".keimen__seed").length === 0) {
      // document.querySelector("html").style.overflow = "scroll";
      setScrollLocked(false);
      seeds = false;
      if (imageInfo[2]) triggerUserInfo(2);
      setKeimenActive(true);
    }
  };

  const triggerUserInfo = (index) => {
    // document.querySelector("html").style.overflow = "scroll";
    setScrollLocked(false);
    imageInfo[index] = false;
    const target = document.querySelector(`.userphoto__info--${index}`);
    target.innerHTML = "";
    const message = `Seed ${seedAmount} Generation ${index}${new Date().toLocaleString()}`;
    const numero = seedAmount.toString().length;
    for (let index2 = 0; index2 < message.length; index2++) {
      setTimeout(() => {
        if (index2 === 5 + numero) {
          var br = document.createElement("br");
          $(target).append(br);
        }
        if (index2 === 18 + numero || index2 === 5 + numero) {
          var br = document.createElement("br");
          $(target).append(br);
        }
        $(target).append(message[index2]);
      }, 100 * index2);
    }
  };

  const scrollAnimation = () => {
    // ANY SCROLL
    if (document.querySelectorAll(".breather").length > 0) {
      for (let i = 0; i < document.querySelectorAll(".breather").length; i++) {
        setTimeout(function () {
          if (document.querySelectorAll(".breather")[i]) {
            document.querySelectorAll(".breather")[i].style.opacity = 0;
          }
        }, i * 100);
        setTimeout(function () {
          if (document.querySelectorAll(".breather")[i]) {
            document.querySelectorAll(".breather")[i].remove();
          }
        }, i * 100 + 500);
      }
    }

    if (scroll > 28000 && seeds) {
      seeds = false;
      setScrollLocked(false);
    }

    if (scroll > 850 && introVid) {
      setScrollLocked(true, 850);
      // document.querySelector("html").style.overflow = "hidden";
      triggerVideoCursor("intro__introVideo");
      introVid = false;
    }

    if (scroll > 1000) {
      introVid = false;
    }

    if (scroll > 3200 && vid) {
      vid = false;
    }

    // SCROLL FORWARDS
    if (scroll > previousScroll) {
      // Gallery
      if (scroll > 235200 && scroll < 235500) {
        console.log("LOCK GALLERY");
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
      }
      // VIDEO
      // if (scroll > 700 && introVid) {
      //   window.alert(scroll);
      //   document.querySelector("html").style.overflow = "hidden";
      //   triggerVideoCursor("intro__introVideo");
      //   introVid = false;
      // }

      // VIDEO
      if (scroll > 3000 && vid) {
        if (presVid) {
          // document.querySelector("html").style.overflow = "hidden";
          setScrollLocked(true);
          triggerVideoCursor("intro__presentationVideo");
          presVid = false;
        }
        vid = false;
      }

      // VIDEO
      // if (scroll > 34000 && scroll < 34100) {
      //   if (vid1) {
      //     document.querySelector(".keimen__vege1 video").muted = false;
      //   }
      // }
      // // VIDEO
      // if (scroll > 50000) {
      //   document.querySelector(".keimen__vege1 video").muted = true;
      // }

      // VIDEO
      if (scroll > 40000 && vid2) {
        // document.querySelector("html").style.overflow = "hidden";
        const totalDuration = document.querySelector(".keimen__vege1 video")
          .duration;
        const current = document.querySelector(".keimen__vege1 video")
          .currentTime;
        const remaining = totalDuration - current;
        vid2 = false;
        // setTimeout(() => {
        //   document.querySelector("html").style.overflow = "scroll";
        // }, remaining * 1000);
        setTimeout(() => {
          document.querySelector(".keimen__slogan").style.display = "block";
          $(".keimen__slogan").animate({ marginLeft: "20vw" }, 1000);
        }, 10000);
      }

      if (scroll > 116000 && scroll > 116100) {
        // $(".blumen__background-bright").mouseMove();
      }
      if (scroll > 118000 && alert) {
        alert = false;
        // console.log(alert);
        if (confirm("Blooming process started")) {
          document.querySelector(".userphoto--4").style.opacity = "1";
          window.scrollTo(0, 119005);
        } else {
          document.querySelector(".userphoto--4").style.opacity = "1";
          window.scrollTo(0, 119005);
        }
      }
      if (scroll > 118000 && devMode) {
        document.querySelector(".userphoto--4").style.opacity = "1";
      }

      // NOT VIDEOS

      if (scroll > 116000 && scroll > 116100) {
        // $(".blumen__background-bright").mouseMove();
      }
      if (scroll > 118000 && devMode) {
        document.querySelector(".userphoto--4").style.opacity = "1";
      }

      if (scroll > 4000 && !audioPlaying) {
        audioPlaying = true;
        document.querySelector(".general-audio").play();
      }

      if (scroll > 1000 && scroll < 3000 && !audioPlaying) {
        audioPlaying = true;
        document.querySelector(".general-audio").play();
      }

      if (scroll > 3000 && scroll < 4000 && audioPlaying) {
        audioPlaying = false;
        document.querySelector(".general-audio").pause();
      }

      if (scroll > 27700 && seeds) {
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
        checkSeeds();
      }

      if (scroll > 171000) {
        document
          .querySelector("#kreuzen")
          .addEventListener("mousemove", (event) => moveBinary(event));
      }
      if (scroll > 197000 && !copied) {
        console.log("COPIED: " + copied);
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
      }
      if (scroll > 199000 && !pasted) {
        console.log("PASTED: " + pasted);
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
      }

      if (scroll > 5900 && seedUploaded === null) {
        setScrollLocked(true);
        // document.querySelector("html").style.overflow = "hidden";
      }

      if (scroll > 7600 && !flowerClicked) {
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
        setTimeout(() => {
          flowerClicked = true;
          document.querySelector(".flower1").style.animation =
            "wave2 infinite 11s ease-in-out";
          // document.querySelector("html").style.overflow = "scroll";
          setScrollLocked(false);
        }, 5000);
      }

      // if (
      //   scroll > 47500 &&
      //   document.querySelector(".keimen__vege1 video").muted === false
      // ) {
      //   document.querySelector(".keimen__vege1 video").muted = true;
      // }
      if (scroll > 249600 && scroll < 250500) {
        // document.querySelector("html").style.overflow = "hidden";
        setScrollLocked(true);
      }
    }

    handleVideos(scroll);

    previousScroll = scroll;
  };

  const handleVideos = (scr) => {
    if (scr > 0 && scr < 1000) {
      setVideoA(true);
      setVideoB(false);
    } else if (scr > 3000 && scr < 6500) {
      setVideoA(false);
      setVideoB(true);
      setVideoC(false);
    } else if (scr > 33900 && scr < 47000) {
      setVideoB(false);
      setVideoC(true);
      setVideoD(false);
      if (!videoCursorDisplayed) triggerVideoCursor("keimen__vege1");
    } else if (scr > 52000 && scr < 64000) {
      // setVideoC(false);
      if (!videoD && !videoCursorDisplayed) triggerVideoCursor("keimen__vege2");
      setVideoD(true);
      setVideoE(false);
    } else if (scr > 74000 && scr < 92000) {
      setVideoD(false);
      setVideoE(true);
      setVideoF(false);
      if (!videoCursorDisplayed) triggerVideoCursor("verzweigen__video1");
    } else if (scr > 92100 && scr < 107000) {
      setVideoE(false);
      setVideoF(true);
      setVideoG(false);
      if (!videoCursorDisplayed) triggerVideoCursor("verzweigen__video2");
    } else if (scr > 132000 && scr < 135000) {
      setVideoF(false);
      setVideoG(true);
      setVideoH(false);
      if (!videoCursorDisplayed) triggerVideoCursor("blumen__video1");
    } else if (scr > 150000 && scr < 153000) {
      setVideoG(false);
      setVideoH(true);
      setVideoI(false);
      if (!videoCursorDisplayed) triggerVideoCursor("blumen__video2");
    } else if (scr > 188000 && scr < 196000) {
      document.querySelector(".kreuzen-audio").pause();
      setVideoH(false);
      setVideoI(true);
      setVideoJ(false);
      if (!videoCursorDisplayed) triggerVideoCursor("kreuzen__video");
    } else if (scr > 204000 && scr < 215000) {
      setVideoI(false);
      setVideoJ(true);
      setVideoK(false);
      if (!videoCursorDisplayed) triggerVideoCursor("welken__video1");
    } else {
      setVideoA(false);
      setVideoB(false);
      setVideoC(false);
      setVideoD(false);
      setVideoE(false);
      setVideoF(false);
      setVideoG(false);
      setVideoH(false);
      setVideoI(false);
      setVideoJ(false);
      setVideoK(false);
      document.querySelector(".video__time").style.opacity = 0;
      videoCursorDisplayed = false;
    }
    // ADD VIDEOK COORDINATES HERE
    // if(scr > 204000 && scr < 215000) {
    //   setVideoJ(false)
    //   setVideoK(true)
    //   if(!videoCursorDisplayed) triggerVideoCursor('welken__video2');
    // }
  };

  const triggerVideoCursor = (videoClass) => {
    videoCursorDisplayed = true;
    document.querySelector(".video__time__cursor").style.animation = "unset";
    document.querySelector(".video__time__cursor p").innerHTML = seed
      ? "Enter"
      : "Enjoy";
    const totalDuration = document.querySelector(`.${videoClass} video`)
      .duration;
    const current = document.querySelector(`.${videoClass} video`).currentTime;
    const remaining = totalDuration - current;
    const delay =
      videoClass === "intro__introVideo" ||
      videoClass === "intro__presentationVideo"
        ? 30
        : 0;
    let scrollAgain = (remaining - delay) * 1000;
    if (delay === 0)
      document.querySelector(".video__time__cursor p").style.opacity = 0;
    else {
      document.querySelector(".video__time__cursor p").style.opacity = 1;
      console.log(seedUploaded);
      if (seedUploaded !== null) scrollAgain = 2000;
    }
    document.querySelector(".video__time").style.opacity = 1;
    setTimeout(() => {
      document.querySelector(
        ".video__time__cursor"
      ).style.animation = `cursor linear ${remaining}s`;
    }, 10);
    if (delay !== 0) {
      setTimeout(() => {
        document.querySelector(".video__time__cursor p").innerHTML = "Enter";
        // document.querySelector("html").style.overflow = "scroll";
        setScrollLocked(false);
      }, scrollAgain);
    }
    // setTimeout(() => {
    //   document.querySelector(".video__time").style.opacity = 0;
    //   videoCursorDisplayed = false;
    // }, (scrollAgain+delay));
  };

  const setFlowerClicked = (value) => {
    flowerClicked = value;
  };

  // console.log("render app");
  return (
    <div className="app" id="app">
      <div 
      data-0="display:none;z-index: 10000100;"
      data-1100="display:block;z-index: 10000100;"
      data-end="display:block;z-index: 10000100;"
      className="userid" onClick={() => setScrollLocked(false)}>{userId}</div>
      {config.debuggerEnabled && <Debugger></Debugger>}
      <Backgrounds />
      <div id="giant-scroll"></div>
      <Audios />
      <People />
      <ScrollIndicator />
      <VideoCursor />
      <Introduction />
      <div className="component-wrapper">
        <Saen
          codeEntered={seed !== null}
          setFlowerClicked={setFlowerClicked}
          flowerClicked={flowerClicked}
        />
        <Keimen checkSeeds={checkSeeds} />
        <Verzweigen branchingPic={branchingPic} />
        <Blumen moveCursor={moveCursor} />
        <Kreuzen moveBinary={moveBinary} />
        <Welken moveCursorSlogan={moveCursorSlogan} />
        <Vermodern />
      </div>
    </div>
  );
};

export default App;
